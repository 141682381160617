<template>
  <div style="height: 0; position: absolute; bottom: 0; left: -100%; opacity: 0; visibility: hidden; overflow: hidden;">
    <div
      v-for="item1 in items"
      :key="item1.id"
    >
      <a :href="`/catalog/${item1.code}-${item1.id}`">
        {{ item1.name }}
      </a>
      <template v-if="item1.items">
        <div
          v-for="item2 in item1.items"
          :key="item2.id"
        >
          <a :href="`/catalog/${item2.code}-${item2.id}`">
            {{ item2.name }}
          </a>
          <template v-if="item2.items">
            <div
              v-for="item3 in item2.items"
              :key="item3.id"
            >
              <a :href="`/catalog/${item3.code}-${item3.id}`">
                {{ item3.name }}
              </a>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, useStore, computed, useFetch } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AppCatalogMenuSeo',
  setup() {
    const { state, dispatch } = useStore();
    const items = computed({
      get: () => state.main.catalogMenu,
      set: () => {},
    });

    useFetch(async () => {
      if (items.value.length) return;
      await dispatch('main/getCatalogMenu');
    });

    return { items };
  },
});
</script>
